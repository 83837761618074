<template>
	<div class="tabulation" :style="{overflow:cat}" v-loading="loading" element-loading-text="加载中"
		element-loading-background="rgb(255, 255, 255, 0.5)">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 标题 -->
					<el-form-item label="标题名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 说明 -->
					<el-form-item label="问卷说明" prop="desc">
						<div class="shuoming">
							<el-input type="textarea" v-model="form.desc" placeholder="请填写说明"></el-input>
						</div>
					</el-form-item>
					<!-- 答题时间 -->
					<el-form-item label="答题时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetimerange" range-separator="截至"
								start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 展示专栏 -->
					<el-form-item label="展示专栏" prop="sex">
						<el-select v-model="form.sex" placeholder="请选择可见范围" size="medium">
							<el-option label="直属机关工会" value="直属机关工会"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="基层工会" value="基层工会"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 可见组织 -->
					<el-form-item label="可见组织" prop="Mars">
						<el-select v-model="form.Mars" placeholder="请选择组织名称" size="medium" @click="multiple"
							@change="numerical" />
					</el-form-item>
					<!-- 是否启用 -->
					<el-form-item label="是否发布" prop="enable">
						<el-select v-model="form.enable" placeholder="请选择是否发布" size="medium">
							<el-option label="是" value="是" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="否" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 问卷模式 -->
					<el-form-item label="问卷模式" prop="pattern">
						<el-select v-model="form.pattern" placeholder="请选择问卷模式" size="medium">
							<el-option label="收集意见" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="答题模式" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 时间限制 -->
					<el-form-item label="时间限制" prop="isSetTime">
						<el-select v-model="form.isSetTime" placeholder="请选择问卷模式" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 答题时间 -->
					<el-form-item label="答题时间" prop="setTime" v-if="form.isSetTime==1 || form.isSetTime== '是'">
						<div class="aaaa">
							<el-input v-model="form.setTime" size="large" placeholder="请填写答题时间(分钟)"></el-input>
						</div>
						<!-- <div class="referrals">
							（提示：免费则输入0。）
						</div> -->
					</el-form-item>
					<!-- 是否与健步配合答题 -->
					<el-form-item label="健步配合" prop="strides">
						<el-select v-model="form.strides" placeholder="请选择是否与健步配合答题" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 达标步数 -->
					<el-form-item label="达标步数" prop="reach">
						<div class="aaaass">
							<el-input v-model="form.reach" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写达标步数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 达标题数 -->
					<el-form-item label="达标题数" prop="problem">
						<div class="aaaass">
							<el-input v-model="form.problem" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写达标题数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 单次题数 -->
					<el-form-item label="单次题数" prop="einmal">
						<div class="aaaass">
							<el-input v-model="form.einmal" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写单次题数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 问题答尽后随机出题 -->
					<el-form-item label="随机出题" prop="random">
						<el-select v-model="form.random" placeholder="问题答尽后是否随机出题" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 限制每日答题次数 -->
					<el-form-item label="答题次数" prop="restrictive">
						<el-select v-model="form.restrictive" placeholder="是否限制答题次数" size="medium">
							<el-option label="按每日限制" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="按总次数限制" value="3"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="不限制" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 限制次数 -->
					<el-form-item label="限制次数" prop="frequency">
						<div class="aaaass">
							<el-input v-model="form.frequency" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写答题次数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 小程序端展示排行 -->
					<el-form-item label="展示排行" prop="exhibition">
						<el-select v-model="form.exhibition" placeholder="是否展示小程序端排行" size="medium">
							<el-option label="公开展示" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="管理员可见" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="全部不展示" value="3" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 小程序端展示历史记录 -->
					<el-form-item label="展示记录" prop="record">
						<el-select v-model="form.record" placeholder="是否展示小程序端历史记录" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 标题图片 -->
					<el-form-item label="主题图片" prop="breviary">
						<el-upload action="/admin/ques/saveImage" list-type="picture-card" limit="1"
							v-model="form.breviary" :auto-upload="true" :on-change="change" :on-error="bedefeated"
							:on-success="handleUpImage" :on-remove="Remove" :class="styA==0&&styD==0?'styB':'styC'"
							:before-upload="beforeImageUpload" :before-remove="beforeremove" :on-preview="onpreview"
							:file-list="approve" accept=".jpeg,.png,.jpg,.bmp,.gif" :headers="header" name="imageFile">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽700px、高400px。)
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button class="button" type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		ref,
		onMounted,
		reactive,
		onActivated
	} from 'vue'
	import {
		Plus
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		DTcompile,
		DTpresent,
		Zzlist
	} from '../../utils/api'
	import qs from 'qs'
	import moments from 'moment'
	import {
		ElMessage
	} from "element-plus";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	export default {
		name: "wjedit",
		components: {
			Plus
		},
		setup() {
			const route = useRoute();
			// 是否滚动
			let cat = ref('hidden')
			let texture1 = ref([])
			const suoshu = () => {
				Zzlist().then((res) => {
					texture1.value = res.data.data.wholeUnionList
					const arr3 = texture1.value.filter(function(num) {
						return num.unionId == hqcode.value
					})
					if (arr3.length == 0) {
						texture1.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionId == hqcode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionId == hqcode.value
									})
									if (arr5.length == 0) {
										items.unionDtoList.forEach((ite) => {
											const arr6 = ite.unionDtoList.filter(
												function(numss) {
													return numss.unionId == hqcode
														.value
												})
											if (arr6.length == 0) {
												ite.unionDtoList.forEach((it) => {
													const arr7 = it
														.unionDtoList.filter(
															function(numsss) {
																return numsss
																	.unionId ==
																	hqcode
																	.value
															})
													if (arr7.length == 0) {
														it.unionDtoList
															.forEach((i) => {
																const
																	arr8 =
																	i
																	.unionDtoList
																	.filter(
																		function(
																			numssss
																		) {
																			return numssss
																				.unionId ==
																				hqcode
																				.value
																		})
																if (arr8
																	.length ==
																	0) {
																	i.unionDtoList
																		.forEach(
																			(
																				s
																				) => {
																				const
																					arr9 =
																					s
																					.unionDtoList
																					.filter(
																						function(
																							nu
																						) {
																							return nu
																								.unionId ==
																								hqcode
																								.value
																						}
																					)
																				if (arr9
																					.length ==
																					0
																				) {
																					s.unionDtoList
																						.forEach(
																							(
																								a
																								) => {
																								const
																									arr10 =
																									a
																									.unionDtoList
																									.filter(
																										function(
																											cat
																										) {
																											return cat
																												.unionId ==
																												hqcode
																												.value
																										}
																									)
																								if (arr10
																									.length ==
																									0
																								) {
																									a.unionDtoList
																										.forEach(
																											(
																												d
																												) => {
																												const
																													arr11 =
																													d
																													.unionDtoList
																													.filter(
																														function(
																															dog
																														) {
																															return dog
																																.unionId ==
																																hqcode
																																.value
																														}
																													)
																												if (arr11
																													.length ==
																													0
																												) {
																													d.unionDtoList
																														.forEach(
																															(
																																f
																																) => {
																																const
																																	arr12 =
																																	f
																																	.unionDtoList
																																	.filter(
																																		function(
																																			long
																																		) {
																																			return long
																																				.unionId ==
																																				hqcode
																																				.value
																																		}
																																	)
																																arr12
																																	.forEach(
																																		(
																																			item
																																			) => {
																																			form.Mars =
																																				arr3
																																				.unionName =
																																				item
																																				.unionName
																																			Hcode
																																				.value =
																																				arr3
																																				.unionCode =
																																				item
																																				.unionCode
																																		}
																																	)
																															}
																														)
																												}
																												arr11
																													.forEach(
																														(
																															item
																															) => {
																															form.Mars =
																																arr3
																																.unionName =
																																item
																																.unionName
																															Hcode
																																.value =
																																arr3
																																.unionCode =
																																item
																																.unionCode
																														}
																													)
																											}
																										)
																								}
																								arr10
																									.forEach(
																										(
																											item
																											) => {
																											form.Mars =
																												arr3
																												.unionName =
																												item
																												.unionName
																											Hcode
																												.value =
																												arr3
																												.unionCode =
																												item
																												.unionCode
																										}
																									)
																							}
																						)
																				}
																				arr9.forEach(
																					(
																						item
																						) => {
																						form.Mars =
																							arr3
																							.unionName =
																							item
																							.unionName
																						Hcode
																							.value =
																							arr3
																							.unionCode =
																							item
																							.unionCode
																					}
																				)
																			}
																		)
																}
																arr8.forEach(
																	(
																		item
																		) => {
																		form.Mars =
																			arr3
																			.unionName =
																			item
																			.unionName
																		Hcode
																			.value =
																			arr3
																			.unionCode =
																			item
																			.unionCode
																	})
															})
													}
													arr7.forEach((item) => {
														form.Mars =
															arr3
															.unionName =
															item
															.unionName
														Hcode.value =
															arr3
															.unionCode =
															item
															.unionCode
													})
												})
											}
											arr6.forEach((item) => {
												form.Mars = arr3.unionName =
													item.unionName
												Hcode.value = arr3.unionCode =
													item.unionCode
											})
										})
									}
									console.log(arr5)
									arr5.forEach((item) => {
										form.Mars = arr3.unionName = item.unionName
										Hcode.value = arr3.unionCode = item.unionCode
									})
								})
							} else {
								arr4.forEach((item) => {
									form.Mars = arr3.unionName = item.unionName
									Hcode.value = arr3.unionCode = item.unionCode
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.Mars = arr3.unionName = item.unionName
							Hcode.value = arr3.unionCode = item.unionCode
						})
					}
					loading.value = false
					cat.value = 'auto'
					// console.log(res)
				})
			}
















			// 选择组织搜索框变化
			let numerical = (e) => {
				if (e == '') {
					Hcode.value = ''
				}
			}
			// 组织列表接口
			const lang = () => {
				Zzlist().then((res) => {
					console.log(res)
					texture.value = res.data.data.wholeUnionList
				})
			}
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				form.Mars = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 搜索组织弹窗
			let visible = ref(false)
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}






			let loading = ref(true)
			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 添加按钮是否显示
			let styD = ref(0)
			// 默认显示已上传文件
			let approve = ref([])
			// 获取组织code
			let hqcode = ref('')
			let messge = () => {
				let data = {
					id: sessionStorage.getItem('DTid')
				}
				DTcompile(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						// 组织id
						hqcode.value = res.data.data.quesInfo.unionId
						// 是否展示历史记录
						if (res.data.data.quesInfo.isLog == 1) {
							form.record = '是'
						} else if (res.data.data.quesInfo.isLog == 2) {
							form.record = '否'
						} else if (res.data.data.quesInfo.isLog == null || res.data.data.quesInfo.isLog ==
							'') {
							form.record = ''
						}
						// 是否展示排行
						if (res.data.data.quesInfo.isRanking == 1) {
							form.exhibition = '公开展示'
						} else if (res.data.data.quesInfo.isRanking == 2) {
							form.exhibition = '管理员可见'
						} else if (res.data.data.quesInfo.isRanking == 3) {
							form.exhibition = '全部不展示'
						} else if (res.data.data.quesInfo.isRanking == null || res.data.data.quesInfo
							.isRanking == '') {
							form.exhibition = ''
						}
						// 限制次数
						form.frequency = res.data.data.quesInfo.dayAnswer
						// 是否限制答题次数
						if (res.data.data.quesInfo.isDayAnswer == 1) {
							form.restrictive = '按每日限制'
						} else if (res.data.data.quesInfo.isDayAnswer == 2) {
							form.restrictive = '不限制'
						} else if (res.data.data.quesInfo.isDayAnswer == 3) {
							form.restrictive = '按总次数限制'
						} else if (res.data.data.quesInfo.isDayAnswer == null || res.data.data.quesInfo
							.isDayAnswer == '') {
							form.restrictive = ''
						}
						// 是否随机出题
						if (res.data.data.quesInfo.isRandomlyQuestions == 1) {
							form.random = '是'
						} else if (res.data.data.quesInfo.isRandomlyQuestions == 2) {
							form.random = '否'
						} else if (res.data.data.quesInfo.isRandomlyQuestions == null || res.data.data.quesInfo
							.isRandomlyQuestions == '') {
							form.random = ''
						}
						loading.value = false
						// 单次题数
						form.einmal = res.data.data.quesInfo.questionsNumber
						// 达标题数
						form.problem = res.data.data.quesInfo.answerStandard
						// 达标步数
						form.reach = res.data.data.quesInfo.stepsNumber
						// 健步配合
						if (res.data.data.quesInfo.isWalk == 2) {
							form.strides = '否'
						} else if (res.data.data.quesInfo.isWalk == 1) {
							form.strides = '是'
						} else if (res.data.data.quesInfo.isWalk == null || res.data.data.quesInfo.isWalk ==
							'') {
							form.strides = ''
						}
						// 问卷模式
						if (res.data.data.quesInfo.category == 2) {
							form.pattern = '答题模式'
						} else if (res.data.data.quesInfo.category == 1) {
							form.pattern = '收集意见'
						} else if (res.data.data.quesInfo.category == null || res.data.data.quesInfo
							.category == '') {
							form.pattern = ''
						}
						//时间限制
						if (res.data.data.quesInfo.isSetTime == 1) {
							form.isSetTime = '是'
						} else if (res.data.data.quesInfo.isSetTime == 2) {
							form.isSetTime = '否'
						}
						//答题时间
						form.setTime = res.data.data.quesInfo.setTime
						// 标题
						form.name = res.data.data.quesInfo.title
						// 说明
						form.desc = res.data.data.quesInfo.rule
						// 可见范围
						if (res.data.data.quesInfo.seeing == 1) {
							form.sex = '直属机关工会'
						} else if (res.data.data.quesInfo.seeing == 2) {
							form.sex = '基层工会'
						} else if (res.data.data.quesInfo.seeing == null || res.data.data.quesInfo.seeing ==
							'') {
							form.sex = ''
						}
						// 是否公开
						if (res.data.data.quesInfo.isEnable == 1) {
							form.enable = '是'
						} else if (res.data.data.quesInfo.isEnable == 2) {
							form.enable = '否'
						} else if (res.data.data.quesInfo.isEnable == null || res.data.data.quesInfo
							.isEnable == '') {
							form.enable = ''
						}
						// 图片
						if (res.data.data.quesInfo.imageUrl == null || res.data.data.quesInfo.imageUrl == '') {
							styD.value = 0
						} else {
							styD.value = 1
							let data = {
								url: res.data.data.quesInfo.imageUrl
							}
							approve.value.push(data)
						}
						suoshu()
						// 时间
						let staet = ref(moments(res.data.data.quesInfo.startTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						let enables = ref(moments(res.data.data.quesInfo.endTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss'))
						initiation.value = res.data.data.quesInfo.startTime
						terminate.value = res.data.data.quesInfo.endTime
						form.value1.push(staet.value, enables.value)
					}
				})
			}
			// 开始时间
			let initiation = ref('')
			// 结束时间
			let terminate = ref('')
			// 时间选择
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),

			]
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 上传图片
			const styA = ref(0)
			const change = (res, fileList) => {
				console.log(form.breviary)
				console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 950).then((res) => {
						console.log(res);
						resolve(res);
					});
				})
			}
			// 上传失败
			const bedefeated = (err) => {
				console.log(err, '失败')
			}
			// 上传成功图片地址
			let succes = ref('')
			// 上传成功，获取返回的图片地址
			const handleUpImage = (res) => {
				console.log(res, '成功')
				succes.value = res.data
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
				styD.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}

			// 提交 按钮
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)



			const router = useRouter();
			const onSubmit = () => {
				var b = ref(new Date(form.value1[0]))
				var begin = ref('')
				begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value.getDate() + ' ' +
					b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value.getSeconds();
				var start = ref(new Date(begin.value).getTime() / 1000)
				// 截至时间
				var d = ref(new Date(form.value1[1]))
				var finish = ref('')
				finish.value = d.value.getFullYear() + '-' + (d.value.getMonth() + 1) + '-' + d.value.getDate() + ' ' +
					d.value.getHours() + ':' + d.value.getMinutes() + ':' + d.value.getSeconds();
				var over = ref(new Date(finish.value).getTime() / 1000)
				// 可见范围判断
				let catchs = ref(null)
				if (form.sex == '直属机关工会') {
					catchs.value = 1
				} else if (form.sex == '基层工会') {
					catchs.value = 2
				} else if (form.sex == 1) {
					catchs.value = 1
				} else if (form.sex == 2) {
					catchs.value = 2
				}
				// 是否公开
				let publicity = ref(null)
				if (form.enable == '是') {
					publicity.value = 1
				} else if (form.enable == '否') {
					publicity.value = 2
				} else if (form.enable == 1) {
					publicity.value = 1
				} else if (form.enable == 2) {
					publicity.value = 2
				}

				// 问卷模式
				let niubi = ref(null)
				if (form.pattern == '收集意见') {
					niubi.value = 1
				} else if (form.pattern == '答题模式') {
					niubi.value = 2
				} else if (form.pattern == 1) {
					niubi.value = 1
				} else if (form.pattern == 2) {
					niubi.value = 2
				}
				//时间限制
				let isSetTimes = ref(null)
				if (form.isSetTime == '是') {
					isSetTimes.value = 1
				} else if (form.isSetTime == '否') {
					isSetTimes.value = 2
				} else if (form.isSetTime == 1) {
					isSetTimes.value = 1
				} else if (form.isSetTime == 2) {
					isSetTimes.value = 2
				}
				// 是否健步配合
				let wakels = ref(null)
				if (form.strides == '是') {
					wakels.value = 1
				} else if (form.strides == '否') {
					wakels.value = 2
				} else if (form.strides == 1) {
					wakels.value = 1
				} else if (form.strides == 2) {
					wakels.value = 2
				}
				// 是否限制答题次数
				let datinumber = ref(null)
				if (form.restrictive == '按每日限制') {
					datinumber.value = 1
				} else if (form.restrictive == '不限制') {
					datinumber.value = 2
				} else if (form.restrictive == '按总次数限制') {
					datinumber.value = 3
				} else if (form.restrictive == 1) {
					datinumber.value = 1
				} else if (form.restrictive == 2) {
					datinumber.value = 2
				} else if (form.restrictive == 3) {
					datinumber.value = 3
				}
				// 是否随机出题
				let suiji = ref(null)
				if (form.random == '是') {
					suiji.value = 1
				} else if (form.random == '否') {
					suiji.value = 2
				} else if (form.random == 1) {
					suiji.value = 1
				} else if (form.random == 2) {
					suiji.value = 2
				}
				// 是否展示排行
				let TMpaihang = ref(null)
				if (form.exhibition == '公开展示') {
					TMpaihang.value = 1
				} else if (form.exhibition == '管理员可见') {
					TMpaihang.value = 2
				} else if (form.exhibition == '全部不展示') {
					TMpaihang.value = 3
				} else if (form.exhibition == 1) {
					TMpaihang.value = 1
				} else if (form.exhibition == 2) {
					TMpaihang.value = 2
				} else if (form.exhibition == 3) {
					TMpaihang.value = 3
				}
				// 是否展示历史记录
				let TMlishi = ref(null)
				if (form.record == '是') {
					TMlishi.value = 1
				} else if (form.record == '否') {
					TMlishi.value = 2
				} else if (form.record == 1) {
					TMlishi.value = 1
				} else if (form.record == 2) {
					TMlishi.value = 2
				}
				console.log('工会code', Hcode.value)
				console.log('是否展示历史记录', TMlishi.value)
				console.log('是否展示排行', TMpaihang.value)
				console.log('次数限制', form.frequency)
				console.log('是否限制答题次数', datinumber.value)
				console.log('是否随机出题', suiji.value)


				console.log('标题', form.name)
				console.log('问卷说明', form.desc)
				console.log('可见范围', form.sex)
				console.log('是否公开', form.enable)
				console.log('成功图', succes.value)
				console.log('开始时间', start.value)
				console.log('结束时间', over.value)
				console.log('答题模式', form.pattern)
				console.log('健步配合', form.strides)
				console.log(catchs.value)

				ruleFormRef.value.validate((valid) => {
					if (valid == true) {
						ConfirmLoading.value = true
						let data = {
							isRandomlyQuestions: suiji.value,
							isDayAnswer: datinumber.value,
							dayAnswer: form.frequency,
							isRanking: TMpaihang.value,
							isLog: TMlishi.value,
							unionCode: Hcode.value,
							id: sessionStorage.getItem('DTid'),
							title: form.name,
							startTime: start.value,
							endTime: over.value,
							isEnable: publicity.value,
							seeing: catchs.value,
							rule: form.desc,
							imageUrl: succes.value,
							category: niubi.value,
							isSetTime: isSetTimes.value,
							setTime: form.setTime,
							isWalk: wakels.value,
							stepsNumber: form.reach,
							answerStandard: form.problem,
							questionsNumber: form.einmal
						}
						DTpresent(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'answer',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)

							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
				})
			}


			// 模拟 ajax 异步获取内容
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					approve.value = []
					messge()
					lang()
				}
			})
			// onMounted(() => {
			//   messge()
			//   lang()
			// })

			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				names: "",
				name: "",
				sex: "",
				desc: "",
				value1: [],
				enable: "",
				radio1: [],
				pattern: '',
				// 每日答题次数
				frequency: '',
				// 小程序端是否展示历史记录
				record: '',
				// 小程序端展示排行
				exhibition: '',
				// 限制每日答题次数
				restrictive: '',
				// 是否随机出题
				random: '',
				// 健步配合
				strides: '',
				// 达标步数
				reach: '',
				// 达标题数
				problem: '',
				// 单次题数
				einmal: ''
			})
			// 验证表单提示
			const rules = {
				pattern: [{
					required: true,
					message: "请选择问卷模式",
					trigger: "change"
				}, ],
				enable: [{
					required: true,
					message: "请选择是否启用",
					trigger: "change"
				}, ],
				value1: [{
					required: true,
					message: "请选择时间",
					trigger: "change"
				}, ],
				desc: [{
					required: true,
					message: "请填写说明",
					trigger: "change"
				}, ],
				name: [{
					required: true,
					message: "请填写标题",
					trigger: "change"
				}, ],
				sex: [{
					required: true,
					message: "请选择分类",
					trigger: "change"
				}, ],
				Mars: [{
					required: true,
					message: "请选择组织",
					trigger: "change"
				}, ],
				isSetTime: [{
					required: true,
					message: "请选择时间限制",
					trigger: "change"
				}, ],
				setTime: [{
					required: true,
					message: "请填写答题时间",
					trigger: "change"
				}, ],
			}
			return {
				cat,
				lang,
				defaultProps,
				texture,
				dendrogram,
				ability,
				numerical,
				visible,
				multiple,
				loading,
				header,
				styD,
				approve,
				defaultTime2,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				change,
				bedefeated,
				handleUpImage,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				mode: 'default',
				// 提交按钮
				onSubmit,
				// 标题验证
				ruleFormRef,
				form,
				rules
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	.button {
		margin-left: 6.5%;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .fortify {
		display: flex;
		align-items: center;
	}

	::v-deep .subs {
		font-size: 14px;
		color: var(--el-text-color-regular);
		margin-left: 2px;
		margin-right: 10px;
	}

	::v-deep .tance {
		display: flex;
		flex-direction: column;
		margin-left: 11px;
	}

	::v-deep .twist {
		margin-left: 13px;
		margin-top: 8px;
		margin-bottom: 20px;
	}

	::v-deep .dati {
		width: 76px;
		/* float: right; */
		display: flex;
		font-size: 14px;
		justify-content: flex-end;
		margin-top: 13px;
	}

	::v-deep .sheet {
		display: flex;
	}

	::v-deep .gao {
		height: 10px;
	}

	::v-deep .red,
	.red:focus:not(.red:hover) {
		color: red;
	}

	::v-deep .red {
		font-size: 20px;
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	::v-deep .red:hover {
		color: #fab6b6;
	}

	::v-deep .bulur,
	.bulur:focus:not(.bulur:hover) {
		color: #409eff;
	}

	::v-deep .bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	::v-deep .bulur:hover {
		color: #a0cfff;
	}

	::v-deep .cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	::v-deep .circus {
		display: flex;
		align-items: center;
		width: 100%;
		// margin-bottom: 10px;
		margin-top: 10px;
	}

	::v-deep .circu {
		display: flex;
		align-items: center;
		width: 100%;
	}

	::v-deep .lation {
		width: 100%;
	}

	::v-deep .shuomings {
		width: 50%;
		margin-bottom: 50px;
	}

	::v-deep .shuoming {
		width: 100%;
	}

	::v-deep .el-textarea {
		width: 50%;
	}

	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	.aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		margin-top: 10px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 80px !important;
		font-weight: bold;
	}
</style>